<template>
  <div class="vx-col md:w-1/1">
    <!-- <perfect-scrollbar> -->
    <div class="vx-col md:w-1/1">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Credit Limit"
            v-model="data.customerLimit.amount"
            name="CreditLimit"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('CreditLimit')"
            >{{ errors.first("CreditLimit") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Invoice Model</label>
          <br />
          <ul class="centerx">
            <li>
              <vs-radio v-model="data.invoiceModel" vs-value="When Shipping"
                >When Shipping</vs-radio
              >&nbsp;&nbsp;&nbsp;
            </li>
            <li>
              <vs-radio v-model="data.invoiceModel" vs-value="Separated"
                >Separated</vs-radio
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Max Open Billess"
            v-model="data.MaxOpenBill"
            name="MaxOpenBill"
            type="number"
          />

          <label for="MaxOpenBill" class="text-xs text-gray-600 mt-1">
            0 = Unlimited
          </label>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">GROSSUP</label>
          <vs-switch
            color="dark"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.grossup"
          >
            <span slot="on">YES</span>
            <span slot="off">NO</span>
          </vs-switch>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">PKP</label>
          <vs-switch
            color="dark"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.isPkp"
          >
            <span slot="on">YES</span>
            <span slot="off">NO</span>
          </vs-switch>
        </div>
      </div>

      <div v-if="data.isPkp == true">
        <div class="vx-row mb-3">
          <div class="vx-col w-full ml-6">
            <vs-input
              class="w-full"
              label="Tax ID Name"
              v-model="data.npwpName"
            />
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full ml-6">
            <vs-input
              class="w-full"
              label="Tax ID Number"
              v-model="data.sppkp"
              name="SPPKP"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full ml-6">
            <vs-input
              class="w-full"
              label="Tax ID Address"
              v-model="data.taxAddress"
              name="taxAddress"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full ml-6">
            <label class="vs-input--label">Tax Type</label>
            <div class="vx-row">
              <div class="vx-col w-full">
                <multiselect
                  v-model="taxType.selected"
                  :options="taxType.options"
                  placeholder="Type to search"
                  track-by="name"
                  label="name"
                  :max-height="125"
                >
                  <span slot="noResult">Oops! No data found</span>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title"
                        >{{ props.option.code }} {{ props.option.name }}</span
                      >
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title"
                        >{{ props.option.code }} {{ props.option.name }}</span
                      >
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- </perfect-scrollbar> -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    customerId: {
      type: Number,
    },
    data: {
      type: Object,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/customer",
        taxType: {
          selected: null,
          options: [],
        },
        deliveryType: {
          selected: null,
          options: [],
        },
        npwpType: {
          selected: "Personal",
          options: ["Personal", "Company"],
        },
      };
    },
    getDataTaxType() {
      this.$vs.loading();
      console.log("dataaaaaaanyaaaa ini bosss ", this.data);
      this.$http
        .get("/api/v1/master/tax-type", {
          params: {
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.taxType.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setTaxTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/tax-type/" + id).then((resp) => {
        if (resp.status == "success") {
          this.taxType.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    paramData() {
      return {
        is_pkp: this.data.isPkp,
        grossup: this.data.grossup,
        npwp: this.data.npwp === "" ? "-" : this.data.npwp,
        tdp: this.data.tdp === "" ? "-" : this.data.tdp,
        siup: this.data.siup === "" ? "-" : this.data.siup,
        npwp_name: this.data.npwpName,
        sppkp: this.data.sppkp === "" ? "-" : this.data.sppkp,
        //domisili: this.data.domisili === "" ? "-" : this.data.domisili,
        tax_address: this.data.taxAddress === "" ? "-" : this.data.taxAddress,
        invoice_model: this.data.invoiceModel,
        amount: this.data.customerLimit.amount,
        nik: this.data.nik,

        npwp_type: this.npwpType.selected,
        tax_type_id: this.taxType.selected ? this.taxType.selected.ID : null,
        max_open_bill: this.data.MaxOpenBill,
      };
    },
    handleClose() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        let amount = 0;
        if (
          this.data.customerLimit.amount != "" ||
          this.data.customerLimit.amount != null
        ) {
          amount = parseInt(
            this.data.customerLimit.amount.toString().replace(/\./g, "")
          );
        }
        if (amount < 1) {
          this.errors.add({
            field: "CreditLimit",
            msg: "The CreditLimit field is must greater than 0",
          });
          result = false;
        }

        if (result) {
          this.$emit("postDataClTax", this.paramData());
          this.$emit("submit", "true");
        }
      });
    },
  },
  mounted() {
    this.getDataTaxType();
    if (this.customerId) {
      console.log("disini yaa", this.customerId);
    }
    if (this.data) {
      if (this.data.taxTypeID) {
        this.setTaxTypeSelected(this.data.taxTypeID);
      }
      console.log("disini yaa", this.data);
    }
  },
  computed: {},
  watch: {
    customerId(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
    "npwpType.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.npwpType.selected = val;
      } else {
        this.npwpType.selected = "Non PKP";
      }
    },

    "data.customerLimit.amount": function (val) {
      if (val != null) {
        val = val.toString().replace(/\./g, "");
        if (isNaN(parseInt(val))) {
          this.errors.add({
            field: "CreditLimit",
            msg: "The CreditLimit field is number required",
          });
        } else {
          this.errors.clear();
        }
        this.data.customerLimit.amount = val
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        this.data.customerLimit.amount = 0;
      }
    },
    "data.isPkp": function (val) {
      console.log("isPkp", val);
    },
    "taxType.selected": function (val) {
      console.log("taxType", val);
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.taxTypeID = val.ID;
      } else {
        this.data.taxTypeID = null;
      }
    },
  },
};
</script>
<style>
.ps {
  height: 800px;
}
</style>
