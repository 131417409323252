<template>
  <multiselect
    v-model="selected"
    :options="options"
    placeholder="Type to search"
    label="name"
    track-by="name"
    @search-change="handleSearch"
    :showNoOptions="false"
    :max-height="125"
  >
    <span slot="noResult">Oops! No data found</span>
  </multiselect>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      selected: null,
      options: [],
      search: "",
    };
  },
  methods: {
    handleSearch(query) {
      if (query != "") {
        this.search = query;
        this.getData();
      }
    },
    getData() {
      let param = {
        search: this.search,
        type: "Business Partner",
      };
      if (this.flagInitiate && this.id) {
        param.id = this.id;
      }
      this.$http
        .get("/api/v1/master/customer", {
          params: param,
        })
        .then((resp) => {
          this.options = resp.data.records;
          if (this.flagInitiate && this.id) {
            if (this.options.length > 0) {
              this.selected = this.options[0];
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setInitiateById() {
      if (this.id) {
        this.search = "";
        this.flagInitiate = true;
        this.getData();
      } else {
        this.getData();
      }
    },
  },
  watch: {
    selected(v) {
      this.options = [];
      if (v) {
        this.options.push(v);
        this.$emit("parentId", v.id);
      } else {
        this.$emit("parentId", null);
      }
    },
    options(v) {
      if (this.flagInitiate && v.length > 0) {
        this.selected = v[0];
        this.flagInitiate = false;
      }
    },
    id() {
      this.setInitiateById();
    },
  },
  mounted() {
    this.setInitiateById();
  },
};
</script>
