<template>
    <div class="vx-col w-5/6 ml-auto mr-auto">
      <span>
        <b v-if="id!=null">Edit Matrix Customer Group</b>
        <b v-else>Create Matrix Customer Group</b>
      </span>
      
      <div class="vx-row mb-6 mt-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Hierarchy Code"
            v-validate="'required'"
            name="Code"
            v-model="data.code"
            :readonly="id != null"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('Code')"
          >{{ errors.first('Code') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Hierarchy Name"
            name="Name"
            v-model="data.name"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Category</label>
          <div class="vx-row">
            <div class="vx-col w-full">
              <multiselect
                v-model="customerCategory.selected"
                :options="customerCategory.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('CustomerCategory')"
            >{{ errors.first("CustomerCategory") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Group 1</label>
          <div class="vx-row">
            <div class="vx-col w-full">
              <multiselect
                v-model="customerGroup1.selected"
                :options="customerGroup1.options"
                placeholder="Type to search"
                track-by="codename"
                label="codename"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.codename }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.codename }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('CustomerGroup1')"
            >{{ errors.first("CustomerGroup1") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Group 2</label>
          <div class="vx-row">
            <div class="vx-col w-full">
              <multiselect
                v-model="customerGroup2.selected"
                :options="customerGroup2.options"
                placeholder="Type to search"
                track-by="codename"
                label="codename"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.codename }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.codename }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('CustomerGroup2')"
            >{{ errors.first("CustomerGroup2") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Group 3</label>
          <div class="vx-row">
            <div class="vx-col w-full">
              <multiselect
                v-model="customerGroup3.selected"
                :options="customerGroup3.options"
                placeholder="Type to search"
                track-by="codename"
                label="codename"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.codename }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.codename }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('CustomerGroup3')"
            >{{ errors.first("CustomerGroup3") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Pricing Group</label>
          <multiselect
            v-model="pricingGroup.selected"
            :options="pricingGroup.options"
            placeholder="Type to search"
            track-by="codename"
            label="codename"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.name }} ({{ props.option.code }})</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.name }} ({{ props.option.code }})</span
                >
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Allow SFA</label>
          <vs-switch
            color="success"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.allowSfa"
          >
            <span slot="on">True</span>
            <span slot="off">False</span>
          </vs-switch>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Status</label>
          <vs-switch
            color="success"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.isActive"
          >
            <span slot="on">Active</span>
            <span slot="off">Inactive</span>
          </vs-switch>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: {
      id: {
        type: Number
      },
      url: {
        type: String
      }
    },
  
    data() {
      return this.initialState();
    },
    methods: {
      initialState() {
        return {
          data: {
            id: null,
            code: "",
            name: "",
            customerCategoryId: null,
            customerGroupId1: null,
            customerGroupId2: null,
            customerGroupId3: null,
            pricingGroupId: null,
            isActive: null,
            allowSfa: null,
          },
          customerCategory: {
            selected: null,
            options: [],
          },       
          customerGroup1: {
            selected: null,
            options: [],
          },      
          customerGroup2: {
            selected: null,
            options: [],
          },      
          customerGroup3: {
            selected: null,
            options: [],
          },
          pricingGroup: {
            selected: null,
            options: [],
          },
        };
      },
      handleSubmit() {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$vs.loading();
            if (this.id) {
              this.putData();
            } else {
              this.postData();
            }
          }
        });
      },      
      paramData() {
        return {
          code: this.data.code,
          name: this.data.name,
          customer_category_id: this.data.customerCategoryId,
          customer_group_id1: this.data.customerGroupId1,
          customer_group_id2: this.data.customerGroupId2,
          customer_group_id3: this.data.customerGroupId3,
          pricing_group_id: this.data.pricingGroupId,
          is_active: this.data.isActive,
          allow_sfa: this.data.allowSfa
        };
      },
      postData() {
        this.$http
          .post(this.url, this.paramData())
          .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "Created Successfully",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      },
      putData() {
        this.$http
          .put(this.url + "/" + this.id, this.paramData())
          .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "Updated Successfully",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      },
      handleClose() {
        window.scrollTo(0, 0);
        this.$emit("close");
      },
      getData() {
        this.$vs.loading();
        this.$http
          .get(this.url + "/" + this.id)
          .then(resp => {
            this.$vs.loading.close();
            console.log(resp)
            if (resp.code == 200) {
              this.data.id = resp.data.id;
              this.data.code = resp.data.hierarchy_code;
              this.data.name = resp.data.hierarchy_name;
              this.data.isActive = resp.data.is_active;
              this.data.allowSfa = resp.data.allow_sfa == 1 ? true : false;
              if (resp.data.customer_category_id) {
                this.setCustomerCategorySelected(resp.data.customer_category_id);
              }
              if (resp.data.cg1_code) {
                this.setCustomerGroup1Selected(resp.data.cg1_code);
              }
              if (resp.data.cg2_code) {
                this.setCustomerGroup2Selected(resp.data.cg2_code);
              }
              if (resp.data.cg3_code) {
                this.setCustomerGroup3Selected(resp.data.cg3_code);
              }
              if (resp.data.pricing_code) {
                this.setPricingGroupSelected(resp.data.pricing_code);
              }
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      },
      getDataCustomerCategory() {
        this.$vs.loading();
        this.$http
          .get("/api/v1/master/customer-category", {
            params: {
              order: "name",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.customerCategory.options = resp.data.records;
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getDataCustomerGroup1() {
        this.$vs.loading();
        this.$http
          .get("/api/v1/master/customer-group", {
            params: {
              order: "name",
              sort: "asc",
              level: "1",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.customerGroup1.options = resp.data.records.map(record => {
                  return {
                      ...record,
                      codename: `${record.code} - ${record.name}`
                  };
              });
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getDataCustomerGroup2() {
        this.$vs.loading();
        this.$http
          .get("/api/v1/master/customer-group", {
            params: {
              order: "name",
              sort: "asc",
              level: "2",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.customerGroup2.options = resp.data.records.map(record => {
                  return {
                      ...record,
                      codename: `${record.code} - ${record.name}`
                  };
              });
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getDataCustomerGroup3() {
        this.$vs.loading();
        this.$http
          .get("/api/v1/master/customer-group", {
            params: {
              order: "name",
              sort: "asc",
              level: "3",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.customerGroup3.options = resp.data.records.map(record => {
                  return {
                      ...record,
                      codename: `${record.code} - ${record.name}`
                  };
              });
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      getDataPricingGroup() {
        this.$vs.loading();
        this.$http
          .get("/api/v1/master/pricing-group", {
            params: {
              order: "name",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              this.pricingGroup.options = resp.data.records.map(record => {
                  return {
                      ...record,
                      codename: `${record.name} (${record.code})`
                  };
              });
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      },
      setCustomerCategorySelected(id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/customer-category/" + id).then((resp) => {
          if (resp.status == "success") {
            this.customerCategory.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      setCustomerGroup1Selected(code) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/customer-group-by-code/" + code).then((resp) => {
          if (resp.status == "success") {
            const record = resp.data
            record.codename = `${record.code} - ${record.name}`;
            this.customerGroup1.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },    
      setCustomerGroup2Selected(code) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/customer-group-by-code/" + code).then((resp) => {
          if (resp.status == "success") {
            const record = resp.data
            record.codename = `${record.code} - ${record.name}`;
            this.customerGroup2.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },  
      setCustomerGroup3Selected(code) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/customer-group-by-code/" + code).then((resp) => {
          if (resp.status == "success") {
            const record = resp.data
            record.codename = `${record.code} - ${record.name}`;
            this.customerGroup3.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },
      setPricingGroupSelected(code) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/pricing-group-by-code/" + code).then((resp) => {
          if (resp.status == "success") {
            const record = resp.data
            record.codename = `${record.name} (${record.code})`;
            this.pricingGroup.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
      },      
    },
    mounted() {
      this.getDataCustomerCategory();
      this.getDataCustomerGroup1();
      this.getDataCustomerGroup2();
      this.getDataCustomerGroup3();
      this.getDataPricingGroup();
      if (this.id) {
        this.getData();
      }
    },
    computed: {},
    watch: {
      id(v) {
        if (v) {
          this.getData();
        } else {
          Object.assign(this.$data, this.initialState());
        }
      },
      "customerCategory.selected": function (val) {
        if (val && val !== undefined) {
          this.errors.clear();
          this.data.customerCategoryId = val.id ? val.id : val.ID;
        } else {
          this.data.customerCategoryId = 0;
        }
      },
      "customerGroup1.selected": function (val) {
        if (val && val !== undefined) {
          this.errors.clear();
          this.data.customerGroupId1 = val.id ? val.id : val.ID;
        } else {
          this.data.customerGroupId1 = null;
        }
      },
      "customerGroup2.selected": function (val) {
        if (val && val !== undefined) {
          this.errors.clear();
          this.data.customerGroupId2 = val.id ? val.id : val.ID;
        } else {
          this.data.customerGroupId2 = null;
        }
      },
      "customerGroup3.selected": function (val) {
        if (val && val !== undefined) {
          this.errors.clear();
          this.data.customerGroupId3 = val.id ? val.id : val.ID;
        } else {
          this.data.customerGroupId3 = null;
        }
      },
      "pricingGroup.selected": function (val) {
        if (val) {
          this.errors.clear();
          this.data.pricingGroupId = val.id ? val.id : val.ID;
        } else {
          this.data.pricingGroupId = null;
        }
      },
    }
  };
  </script>
  <style>
    .vs-input--label{
      font-weight: bold;
    }
  </style>
  