<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id!=null">Edit</b>
      <b v-else>Create</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Code"
          v-validate="'required'"
          name="Code"
          v-model="data.code"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('Code')"
        >{{ errors.first('Code') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{ errors.first('Name') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Allow SO Return Without Ref</label>
        <vs-switch
          color="success"
          icon-pack="feather"
          vs-icon-on="icon-check-circle"
          vs-icon-off="icon-slash"
          v-model="data.sr_without_ref"
        >
          <span slot="on">True</span>
          <span slot="off">False</span>
        </vs-switch>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number
    },
    url: {
      type: String
    }
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          name: "",
          code: "",
          sr_without_ref: false
        }
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      return {
        name: this.data.name,
        code: this.data.code,
        sr_without_ref: this.data.sr_without_ref,
        level: 3
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.id = resp.data.ID;
            this.data.name = resp.data.name;
            this.data.code = resp.data.code;
            this.data.sr_without_ref = resp.data.sr_without_ref;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    }
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    }
  }
};
</script>
<style >
</style>
