<template>
  <vx-card class="vx-col md:w-11/12">
    <span>
      <h4>
        <b v-if="customerId != null">Edit Customer</b>
        <b v-else>Create Customer</b>
      </h4>
    </span>
    <vs-tabs :value="active">
      <vs-tab label="General" @click="setTabFormActive('General')">
        <div class="con-tab-ejemplo">
          <general-tab
            @close="handleClose"
            :data="data"
            :customerId="customerId"
            @postDataGeneral="postDataGeneral"
            @tab="setTabFormActive"
          ></general-tab>
        </div>
      </vs-tab>
      <vs-tab label="Attribute" @click="setTabFormActive('Attribute')">
        <div class="con-tab-ejemplo">
          <Attribute
            @close="handleClose"
            :data="data"
            :customerId="customerId"
            @tab="setTabFormActive"
            @postDataAttribute="postDataAttribute"
          ></Attribute>
        </div>
      </vs-tab>
      <vs-tab label="CP" @click="setTabFormActive('CP')">
        <div class="con-tab-ejemplo">
          <Cp
            @close="handleClose"
            :data="data"
            :customerId="customerId"
            @tab="setTabFormActive"
            @postDataCp="postDataCp"
          ></Cp>
        </div>
      </vs-tab>
      <vs-tab label="CL & Tax" @click="setTabFormActive('CL & Tax')">
        <div class="con-tab-ejemplo">
          <Cl
            @close="handleClose"
            :data="data"
            :customerId="customerId"
            @submit="pullOrPush"
            @postDataClTax="postDataClTax"
          ></Cl>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import GeneralTab from "./tab-form/general-tab.vue";
import Attribute from "./tab-form/attribute-tab.vue";
import Cp from "./tab-form/cp-tab.vue";
import Cl from "./tab-form/cl-tx-tab.vue";

export default {
  components: {
    GeneralTab,
    Attribute,
    Cp,
    Cl,
  },
  props: {
    active: {
      type: Number,
    },
    customerId: {
      type: Number,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/customer",
        data: {
          parentID: null,
          bussinessPartnerId: null,
          customerCategoryId: null,
          customerGroupId: null,
          customerGroupId1: null,
          customerGroupId2: null,
          customerGroupId3: null,
          paymentTermId: null,
          paymentTypeId: null,
          deliveryTypeId: null,
          pricingGroupId: null,
          code: "",
          codeExternal: "",
          name: "",
          contactName: "",
          phone: "",
          mobile: "",
          email: "",
          password: "",
          fax: "",
          isPkp: true,
          grossup: false,
          npwp: "",
          npwpType: "",
          tdp: "",
          siup: "",
          npwpName: "",
          sppkp: "",
          //domisili: "",
          taxAddress: "",
          taxTypeID: null,
          nik: "",
          photo: null,
          invoiceModel: "When Shipping",
          priorityType: "Platinum",
          customerLimit: {
            amount: 0,
          },
          customerZone: {
            zoneId: null,
          },
          address: {
            address: "",
            postalCode: "",
            countryName: "",
            provinceName: "",
            cityName: "",
            districtName: "",
            subdistrictName: "",
          },
          blockStatus: 0,
          type: "",
          MaxOpenBill: 0,
          paymentType: "",
          status: true,
        },
        selected: {
          tab: 0,
          itemKit: {},
        },
      };
    },
    setTabFormActive(val) {
      console.log("setTabForm", val);
      console.log("setTabForm", this.active);
      switch (val) {
        case "General":
          this.active = 0;
          break;
        case "Attribute":
          this.active = 1;
          break;
        case "CP":
          this.active = 2;
          break;
        case "CL & Tax":
          this.active = 3;
          break;
        default:
          this.active = 0;
          break;
      }
      console.log("setTabForm2", this.active);
    },

    selectedTab(value, itemKit) {
      alert("selectedTab");
      console.log("selectedTab", value);
      this.selected.itemKit = itemKit;
      this.selected.tab = value;
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!this.data.customerCategoryId) {
          this.errors.add({
            field: "CustomerCategory",
            msg: "Distribution Channel field is required",
          });
          result = false;
        }
        if (!this.data.customerGroupId1) {
          this.errors.add({
            field: "CustomerGroup1",
            msg: "Customer Group 1 field is required",
          });
          result = false;
        }
        if (!this.data.paymentTermId) {
          this.errors.add({
            field: "PaymentTerm",
            msg: "Payment Term field is required",
          });
          result = false;
        }
        if (!this.data.email) {
          this.errors.add({
            field: "Email",
            msg: "The Email field is required",
          });
          result = false;
        }
        if (!this.data.password && this.customerId == null) {
          this.errors.add({
            field: "Password",
            msg: "The Password field is required",
          });
          result = false;
        }
        if (this.data.password.length < 8 && this.customerId == null) {
          this.errors.add({
            field: "Password",
            msg: "The Password at least 8 character",
          });
          result = false;
        }
        let amount = 0;
        if (
          this.data.customerLimit.amount != "" ||
          this.data.customerLimit.amount != null
        ) {
          amount = parseInt(
            this.data.customerLimit.amount.toString().replace(/\./g, "")
          );
        }
        if (amount < 1) {
          this.errors.add({
            field: "CreditLimit",
            msg: "The CreditLimit field is must greater than 0",
          });
          result = false;
        }
        if (result) {
          console.log("this.data.photo", this.data.photo)
          var file = this.data.photo;
          if (file != null) {
            var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
            if (!validImageTypes.includes(file.type)) {
              this.$vs.notify({
                color: "danger",
                title: "Form Validation",
                text: "File must be an image",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              var name = this.data.code + "." + file.name.split(".").pop();
              this.data.photo = name;
              this.formData = new FormData();
              this.formData.append("file", file);
              this.formData.append("name", name);

              if (this.customerId) {
                this.putData();
              } else {
                this.postData();
              }
            }
          } else {
            if (this.customerId) {
              this.putData();
            } else {
              this.postData();
            }
          }
        }
      });
    },
    paramData() {
      let is_pkp = "0";
      if (this.data.isPkp) {
        is_pkp = "1";
      } else {
        this.data.npwp = "-";
        this.data.tdp = "-";
        this.data.siup = "-";
        this.data.sppkp = "-";
        //this.data.domisili = "-";
        this.data.taxAddress = "-";
        this.data.npwpType = "Non PKP";
      }

      let grossup = 0
      if (this.data.grossup) {
        grossup = 1
      }

      // var territoryIds = [];
      // if (Array.isArray(this.territory.selected)) {
      //   this.territory.selected.forEach(function (item) {
      //     territoryIds.push(item.id ? item.id : item.ID);
      //   }, territoryIds);
      // } else {
      //   territoryIds.push(this.territory.selected.id ? this.territory.selected.id : this.territory.selected.ID);
      // }
      let amount = 0;
      if (this.data.customerLimit.amount != "") {
        amount = parseInt(
          this.data.customerLimit.amount.toString().replace(/\./g, "")
        );
      }

      let status = "Active";
      if (this.data.status == true) {
        status = "Active";
      } else {
        status = "Inactive";
      }

      return {
        parent_id: this.data.parentId ? this.data.parentId : null,
        customer_group_id1: this.data.customerGroupId1
          ? this.data.customerGroupId1
          : null,
        customer_group_id2: this.data.customerGroupId2
          ? this.data.customerGroupId2
          : null,
        customer_group_id3: this.data.customerGroupId3
          ? this.data.customerGroupId3
          : null,
        payment_term_id: this.data.paymentTermId
          ? this.data.paymentTermId
          : null,
        pricing_group_id: this.data.pricingGroupId
          ? this.data.pricingGroupId
          : null,
        payment_method_id: this.data.paymentTypeId
          ? this.data.paymentTypeId
          : null,
        customer_category_id: this.data.customerCategoryId
          ? this.data.customerCategoryId
          : null,
        delivery_type_id: this.data.deliveryTypeId
          ? this.data.deliveryTypeId
          : null,
        //territory_ids: territoryIds,
        code: this.data.code,
        code_external: this.data.codeExternal,
        name: this.data.name,
        // address: this.data.address,
        //zone_id: this.data.customerZone.zoneId,
        //sales_personal_id: this.sales.selected.ID,
        // city: this.data.city,
        country: this.data.address.countryName,
        province: this.data.address.provinceName,
        city: this.data.address.cityName,
        district: this.data.address.districtName,
        sub_district: this.data.address.subdistrictName,
        address: this.data.address.address,
        postal_code: this.data.address.postalCode,
        contact_name: this.data.contactName,
        mobile: this.data.mobile,
        phone: this.data.phone,
        email: this.data.email,
        password: this.data.password,
        fax: this.data.fax === "" ? "" : this.data.fax,
        // is_tax_collector: is_tax_collector,
        // is_tax_exempt: is_tax_exempt,
        is_pkp: is_pkp,
        grossup: grossup,
        npwp: this.data.npwp === "" ? "-" : this.data.npwp,
        tdp: this.data.tdp === "" ? "-" : this.data.tdp,
        siup: this.data.siup === "" ? "-" : this.data.siup,
        npwp_name: this.data.npwpName,
        sppkp: this.data.sppkp === "" ? "-" : this.data.sppkp,
        //domisili: this.data.domisili === "" ? "-" : this.data.domisili,
        tax_address: this.data.taxAddress === "" ? "-" : this.data.taxAddress,
        tax_type_id: this.data.taxTypeID ? this.data.taxTypeID.ID : null,
        invoice_model: this.data.invoiceModel,
        status: status,
        type: "End Customer",
        priority_type: this.data.priorityType,
        amount: amount,
        nik: this.data.nik,
        photo: this.data.photo,
        npwp_type: this.data.npwpType,
        payment_type: this.data.paymentType,
        block_status: this.data.blockStatus,
        max_open_bill: Number(this.data.MaxOpenBill),
      };
    },
    postData() {
      this.$vs.loading();
      this.$http
        .post("api/v1/master/customer", this.paramData())
        .then((resp) => {
          console.log("resp customer", resp)
          if (resp.code == 200) {
            this.$http
              .post("api/v1/master/customer/photo/"+resp.data.ID, this.formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((resp) => {
                if (resp.status == "success") {
                  this.handleClose();
                  this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: "Customer Updated",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                } else {
                  this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                }
              });

            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      console.log("putData this.data", this.data);
      console.log("putData this.paramData()", this.paramData());

      this.$vs.loading();
      this.$http
        .put(this.baseUrl + "/" + this.customerId, this.paramData())
        .then((resp) => {
          if (resp.code == 200) {
            this.$http
              .post("api/v1/master/customer/photo/"+this.customerId, this.formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((resp) => {
                if (resp.status == "success") {
                  this.handleClose();
                  this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: "Customer Updated",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                } else {
                  this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                }
              });

            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getData() {
      this.$vs.loading();
      if (this.customerId) {
        this.$http
          .get(this.baseUrl + "/" + this.customerId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              console.log("resp.data>>>>>>>>", resp.data);
              console.log("resp.data>>>>>>>>", resp.data.gross_up);
              this.data.parentId = resp.data.parent_id;
              this.data.code = resp.data.code;
              this.data.codeExternal = resp.data.code_external;
              this.data.name = resp.data.name;
              // this.data.address = resp.data.address;
              this.data.contactName = resp.data.contact_name;
              this.data.phone = resp.data.phone;
              this.data.mobile = resp.data.mobile;
              this.data.email = resp.data.email;
              this.data.fax = resp.data.fax;
              this.data.paymentMethodId = resp.data.payment_method_id;
              this.data.nik = resp.data.nik;
              this.data.photo = resp.data.photo + "?" + new Date().getTime();
              if (resp.data.invoice_model) {
                this.data.invoiceModel = resp.data.invoice_model;
              }
              if (resp.data.priority_type) {
                this.data.priorityType = resp.data.priority_type;
              }
              if (resp.data.npwp_type) {
                this.data.npwpType = resp.data.npwp_type;
              }

              if (resp.data.status) {
                if (resp.data.status == "Active") {
                  this.data.status = true;
                } else {
                  this.data.status = false;
                }
              }
              // this.city.name = resp.data.city;
              this.data.address.address = resp.data.address;
              this.data.address.postalCode = resp.data.postal_code;
              this.data.address.countryName = resp.data.country;
              this.data.address.provinceName = resp.data.province;
              this.data.address.subdistrictName = resp.data.sub_district;
              this.data.address.cityName = resp.data.city;
              this.data.address.districtName = resp.data.district;
              if (resp.data.is_pkp == "1") {
                this.data.isPkp = true;
              } else {
                this.data.isPkp = false;
              }
              this.data.npwp = resp.data.npwp;
              this.data.tdp = resp.data.tdp;
              this.data.siup = resp.data.siup;
              this.data.npwpName = resp.data.npwp_name;
              this.data.sppkp = resp.data.sppkp;
              //this.data.domisili = resp.data.domisili;
              this.data.taxAddress = resp.data.tax_address;
              this.data.customerLimit.amount = resp.data.customer_limit.amount;
              //this.data.customerZone.zoneId = resp.data.customer_zone.zone_id;

              this.data.customerCategoryId = resp.data.customer_category_id;
              this.data.customerGroupId1 = resp.data.customer_group_id1;
              this.data.customerGroupId2 = resp.data.customer_group_id2;
              this.data.customerGroupId3 = resp.data.customer_group_id3;
              this.data.pricingGroupId = resp.data.pricing_group_id;
              this.data.paymentTermId = resp.data.payment_term_id;
              this.data.deliveryTypeId = resp.data.delivery_type_id;
              this.data.taxTypeID = resp.data.tax_type_id;
              this.data.paymentTypeId = resp.data.payment_method_id;
              this.data.MaxOpenBill = resp.data.max_open_bill;
              if (resp.data.gross_up == 1) {
                this.data.grossup = true;
              } else {
                this.data.grossup = false;
              }

              // if (resp.data.customer_zone.zone_id) {
              //   this.sales.selected = null;
              //   this.setZoneSelected(resp.data.customer_zone.zone_id);
              //   this.getDataSales(resp.data.customer_zone.zone_id);
              // } else {
              //   this.zone.selected = [];
              //   this.sales.selected = null;
              // }
              // this.territory.selected = [];
              // var getDataTerritory = this.getDataTerritory;
              // resp.data.customer_territory.forEach(function (item) {
              //   getDataTerritory(item.territory_id);
              // }, getDataTerritory);
              // if (resp.data.sales_personal_id) {
              //   this.setSalesPersonalSelected(resp.data.sales_personal_id);
              // }
              console.log("testt data", this.data);
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },

    getCode() {
      this.$vs.loading();
      this.$http.get("/api/v1/setting/master-number-code/CUST").then((resp) => {
        console.log('Response received', resp);
        if (resp.status == "success") {
          this.data.code = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      }).catch((error) => {
        console.log('Error occurred', error);
      });
    },

    postDataGeneral(val) {
      console.log("postDataGeneral>>>>>>>", val);
      this.data.code = val.code;
      this.data.codeExternal = val.code_external;
      this.data.name = val.name;
      //  this.data.address = val.address;
      // this.data.customerZone.zoneId = val.zone_id;
      // this.sales.selected.ID = val.sales_personal_id;
      // this.data.city = val.city;
      this.data.address.countryName = val.country;
      this.data.address.provinceName = val.province;
      this.data.address.cityName = val.city;
      this.data.address.districtName = val.district;
      this.data.address.subdistrictName = val.sub_district;
      this.data.address.address = val.address;
      this.data.address.postalCode = val.postal_code;
      this.data.status = val.status;
      console.log("this.data!!!!!!", this.data);
    },
    postDataAttribute(val) {
      console.log("postDataAttribute>>>>>>>", val);
      this.data.parentId = val.parent_id;
      this.data.bussinessPartnerId = val.bussinessPartner;
      this.data.customerCategoryId = val.customer_category_id;
      this.data.customerGroupId1 = val.customer_group_id1;
      this.data.customerGroupId2 = val.customer_group_id2;
      this.data.customerGroupId3 = val.customer_group_id3;
      this.data.paymentTermId = val.payment_term_id;
      this.data.pricingGroupId = val.pricing_group_id;
      this.data.paymentTypeId = val.payment_method_id;
      this.data.deliveryTypeId = val.delivery_type_id;
      this.data.priorityType = val.priority_type;
      this.data.blockStatus = val.block_status;
      this.data.type = val.type;

      console.log("this.data!!!!!!", this.data);
    },
    postDataCp(val) {
      console.log("postDataCp>>>>>>>", val);
      this.data.contactName = val.contact_name;
      this.data.mobile = val.mobile;
      this.data.phone = val.phone;
      this.data.email = val.email;
      this.data.fax = val.fax;
      this.data.nik = val.nik;
      this.data.photo = val.photo;

      console.log("this.data!!!!!!", this.data);
    },
    postDataClTax(val) {
      console.log("postDataClTax>>>>>>>", val);
      this.data.isPkp = val.is_pkp;
      this.data.npwp = val.npwp;
      this.data.tdp = val.tdp;
      this.data.siup = val.siup;
      this.data.npwpName = val.npwp_name;
      this.data.sppkp = val.sppkp;
      //this.data.domisili = val.domisili;
      this.data.taxAddress = val.tax_address;
      this.data.taxTypeID = val.tax_type_id;
      this.data.MaxOpenBill = val.max_open_bill;
      this.data.grossup = val.grossup;
      console.log("this.data!!!!!!", this.data);
    },

    pullOrPush(val) {
      console.log("pullOrPush>>>>>>>", val);
      if (val) {
        this.handleSubmit();
      }
      console.log("this.data!!!!!!", this.data);
    },
  },
  mounted() {
    this.getCode();
    //this.getAllDataTerritory();
    if (this.customerId) {
      this.getData();
    }
  },
  // computed: {},
  watch: {
    customerId(v) {
      if (v) {
        this.getData();
      }
    },
  },
};
</script>
