<template>
  <div class="vx-col md:w-1/1">
    <!-- <perfect-scrollbar> -->
    <div class="vx-col md:w-1/1">
      <!-- <div class="vx-row mb-6" v-if="customerId != null">
          <div class="vx-col w-full">
            <vs-input disabled class="w-full" label="Code" v-model="data.code" />
          </div>
        </div> -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Code" v-model="data.code" disabled />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="External Code"
            v-model="data.codeExternal"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Customer Name"
            v-validate="'required'"
            name="Name"
            v-model="data.name"
          />
          <span class="text-danger text-sm" v-show="errors.has('Name')">{{
            errors.first("Name")
          }}</span>
        </div>
      </div>
      <span class="text-danger text-sm" v-show="errors.has('CountryName')">{{
        errors.first("CountryName")
      }}</span>
      <address-component
        :data="data.address"
        @data="setDataAddress"
      ></address-component>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Status</label>
          <vs-switch
            color="success"
            icon-pack="feather"
            vs-icon-on="icon-check-circle"
            vs-icon-off="icon-slash"
            v-model="data.status"
          >
            <span slot="on">Active</span>
            <span slot="off">Inactive</span>
          </vs-switch>
        </div>
      </div>
    </div>

    <!-- </perfect-scrollbar> -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">NEXT</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import AddressComponent from "@/components/master/Address";

export default {
  components: {
    AddressComponent,
  },
  props: {
    customerId: {
      type: Number,
    },
    data: {
      type: Object,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/customer",
        npwpType: {
          selected: "Personal",
          options: ["Personal", "Company"],
        },
      };
    },
    handleClose() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!this.data.name) {
          this.errors.add({
            field: "Name",
            msg: "Customer name field is required",
          });
          result = false;
        }
        if (!this.data.address.countryName) {
          this.errors.add({
            field: "CountryName",
            msg: "Country field is required",
          });
          result = false;
        }

        if (result) {
          this.$emit("postDataGeneral", this.paramData());
          this.$emit("tab", "Attribute");
        }
      });
    },
    paramData() {
      return {
        code: this.data.code,
        code_external: this.data.codeExternal,
        name: this.data.name,
        // address: this.data.address,
        //zone_id: this.data.customerZone.zoneId,
        //sales_personal_id: this.sales.selected.ID,
        // city: this.data.city,
        country: this.data.address.countryName,
        province: this.data.address.provinceName,
        city: this.data.address.cityName,
        district: this.data.address.districtName,
        address: this.data.address.address,
        postal_code: this.data.address.postalCode,
        sub_district: this.data.address.subdistrictName,
        status: this.data.status,
      };
    },

    getCode() {
      this.$vs.loading();
      this.$http.get("/api/v1/setting/master-number-code/CUST").then((resp) => {
        if (resp.status == "success") {
          this.data.code = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setDataAddress(val) {
      this.data.address = val;
    },
  },
  mounted() {
    this.getCode();
    //this.getAllDataTerritory();
    if (this.customerId) {
      console.log("disini yaa", this.customerId);
    }
    if (this.data) {
      console.log("disini yaa", this.data);
    }
  },
  computed: {},
  watch: {},
};
</script>
<style>
.ps {
  height: 800px;
}
</style>
