<template>
  <vs-tabs :value="active">
      <vs-tab label="Customer Group 1">
        <div class="tab-text">
          <customer-group1-component @tab="setTab"></customer-group1-component>
        </div>
      </vs-tab>
      <vs-tab label="Customer Group 2">
        <div class="tab-text">
          <customer-group2-component></customer-group2-component>
        </div>
      </vs-tab>
      <vs-tab label="Customer Group 3">
        <div class="tab-text">
          <customer-group3-component></customer-group3-component>
        </div>
      </vs-tab>
    </vs-tabs>
</template>
<script>
import CustomerGroup1Component from "./customer-group1";
import CustomerGroup2Component from "./customer-group2";
import CustomerGroup3Component from "./customer-group3";
export default {
  components: {
    CustomerGroup1Component,
    CustomerGroup2Component,
    CustomerGroup3Component,
  },
  data() {
    return {
      active: 0,
    };
  },
  mounted() {},
  methods: {
    setTab(val) {
      switch (val) {
        case "Customer Group 2":
          this.active = 1;
          break;
        case "Customer Group 3":
          this.active = 2;
          break;
        default:
          this.active = 0;
          break;
      }
    }
  }
};
</script>