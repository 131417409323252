<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="name"
      label="name"
      :max-height="125"
      :allow-empty="false"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number
    }
  },
  data() {
    return {
      baseUrl: "/api/v1/master/payment-method",
      options: [],
      selected: null
    };
  },
  methods: {
    getAllData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            order: "name",
            sort: "asc"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            var options = [];
            resp.data.records.forEach(function(item) {
              options.push(item);
            }, options);
            this.options = options;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setSelected() {
      const id = this.id;
      const selected = this.options.filter((item => item.ID == id))
      if (selected.length > 0) {
        this.selected = selected[0];
      } else {
        this.selected = null
      }
    }
  },
  watch: {
    selected(v) {
      console.log('selected', this.selected, v)
      if (this.selected != null) {
        // if (v.ID != this.selected.ID) {
          this.selected = v;
          if (v) {
            this.$emit("data", v.ID);
          } else {
            this.$emit("data", null);
          }
        // }
      }
    },
    id() {
      this.setSelected();
    },
    options() {
      this.setSelected();
    }
  },
  mounted() {
    this.getAllData();
  }
};
</script>