<template>
  <vx-card>
  <!-- <vx-card :title="title"> -->
    <vs-tabs :value="active">
      <!-- <vs-tab label="Customer">
        <div class="tab-text">
          <customer-component @tab="setTab"></customer-component>
        </div>
      </vs-tab> -->
      <vs-tab label="Default Payment Term & Credit Limit">
        <div class="tab-text">
          <default-payment-term-component @tab="setTab"></default-payment-term-component>
        </div>
      </vs-tab>
      <vs-tab label="Customer Group">
        <div class="tab-text">
          <customer-group-component></customer-group-component>
        </div>
      </vs-tab>
      <vs-tab label="Distribution Channel">
        <div class="tab-text">
          <customer-category-component></customer-category-component>
        </div>
      </vs-tab>
      <vs-tab label="Pricing Group">
        <div class="tab-text">
          <pricing-group-component></pricing-group-component>
        </div>
      </vs-tab>
      <vs-tab label="Risk Category">
        <div class="tab-text">
          <risk-category-component></risk-category-component>
        </div>
      </vs-tab>
      <vs-tab label="Matrix Customer Group">
        <div class="tab-text">
          <matrix-customer-group-component></matrix-customer-group-component>
        </div>
      </vs-tab>
      <!-- <vs-tab label="Payment Term">
        <div class="tab-text">
          <payment-term-component></payment-term-component>
        </div>
      </vs-tab> -->
    </vs-tabs>
  </vx-card>
</template>
<script>
import CustomerComponent from "./customer";
import CustomerGroupComponent from "./customer-group";
import CustomerCategoryComponent from "./customer-category";
import PricingGroupComponent from "./pricing-group";
import DefaultPaymentTermComponent from "./default-payment-term";
import RiskCategoryComponent from "./risk-category";
import MatrixCustomerGroupComponent from "./matrix-customer-group";
// import PaymentTermComponent from "./payment-term";
export default {
  components: {
    CustomerComponent,
    CustomerGroupComponent,
    CustomerCategoryComponent,
    PricingGroupComponent,
    DefaultPaymentTermComponent,
    RiskCategoryComponent,
    MatrixCustomerGroupComponent,
    // PaymentTermComponent
  },
  data() {
    return {
      active: 0,
      title: "Customer"
    };
  },
  mounted() {},
  methods: {
    setTab(val) {
      switch (val) {
        case "Customer Group":
          this.active = 1;
          break;
        case "Distribution Channel":
          this.active = 2;
          break;
        case "Payment Term":
          this.active = 3;
          break;
        case "Pricing Group":
          this.active = 4;
          break;
        case "Risk Category":
          this.active = 4;
          break;
        case "Matrix Customer Group":
          this.active = 5;
          break;
        default:
          this.active = 0;
          break;
      }
    }
  }
};
</script>