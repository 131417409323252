<template>
    <vx-card title="Matrix Customer Group">
      <vs-button
        class="mt-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
      >Create</vs-button>
      <div class="vx-row mb-12">
        <div v-bind:class="[detail ? detailShow : '', detailHide]">
          <vs-table
            search
            stripe
            border
            description
            :sst="true"
            :data="table.data"
            :max-items="table.length"
            :total="table.total"
            @search="handleSearch"
            @change-page="handleChangePage"
            @sort="handleSort"
          >
            <template slot="header">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                  class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                  <span class="mr-2">{{this.table.start}} - {{this.table.end}} of {{this.table.total}}</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item
                    v-for="item in table.limits"
                    :key="item"
                    @click="handleChangelength(item)"
                  >
                    <span>{{item}}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </template>
            <template slot="thead">
              <vs-th width="5%"></vs-th>
              <vs-th sort-key="Code">Hierarchy Code</vs-th>
              <vs-th sort-key="name">Hierarchy Name</vs-th>
              <vs-th sort-key="cc-name">Customer Category Name</vs-th>
              <vs-th sort-key="cg1">Customer Group 1</vs-th>
              <vs-th sort-key="cg2">Customer Group 2</vs-th>
              <vs-th sort-key="cg3">Customer Group 3</vs-th>
              <vs-th sort-key="pricing">Pricing</vs-th>
            </template>
  
            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td class="whitespace-no-wrap">                  
                  <feather-icon
                    title="Edit"
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    class="ml-2"
                    @click.stop="handleEdit(data[indextr].ID)" />
                  <feather-icon
                    title="Delete"
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="ml-2"
                    @click.stop="handleDelete(data[indextr].ID)" />
                </vs-td>
                <vs-td :data="data[indextr].hierarchy_code">{{ data[indextr].hierarchy_code }}</vs-td>
                <vs-td :data="data[indextr].hierarchy_name">{{ data[indextr].hierarchy_name }}</vs-td>
                <vs-td :data="data[indextr].customer_category_name">{{ data[indextr].customer_category_name }}</vs-td>
                <vs-td>
                    <p>{{ data[indextr].cg1_code +" - "+ data[indextr].cg1_name }}</p>
                </vs-td>
                <vs-td>
                    <p>{{ data[indextr].cg2_code +" - "+ data[indextr].cg2_name }}</p>
                </vs-td>
                <vs-td>
                    <p>{{ data[indextr].cg3_code +" - "+ data[indextr].cg3_name }}</p>
                </vs-td>
                <vs-td>
                    <p>{{ data[indextr].pricing_name }} ({{ data[indextr].pricing_code }})</p>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination style="padding-top:5px" :total="table.totalPage" v-model="setPage" />
        </div>
        <!-- form -->
        <transition name="detail-fade">
          <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
            <vs-row>
              <vs-col
                style="padding-bottom: 8px"
                vs-offset="8"
                vs-type="flex"
                vs-justify="rigth"
                vs-align="rigth"
                vs-w="4"
              >
                <vs-button
                  class="ml-auto"
                  size="small"
                  v-on:click="handleClose"
                  color="grey"
                  icon-pack="feather"
                  icon="icon-x-square"
                >Close</vs-button>
              </vs-col>
              <vs-col
                class="vertical-divider"
                vs-offset="0"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="1"
              >
                <vs-button
                  size="small"
                  v-on:click="handleClose"
                  color="grey"
                  icon-pack="feather"
                  icon="icon-x-square"
                ></vs-button>
              </vs-col>
  
              <!-- form component -->
              <form-component @close="handleClose" :id="id" :url="baseUrlEdit"></form-component>
            </vs-row>
          </div>
        </transition>
      </div>
    </vx-card>
  </template>
  <script>
  import FormComponent from "./form";
  export default {
    components: {
      FormComponent
    },
    data() {
      return {
        baseUrl: "/api/v1/master/matrix-customer-group",
        baseUrlEdit: "/api/v1/master/matrix-customer-group",
        detailShow: "core vx-col md:w-1/2 w-full mb-base ",
        detailHide: "core vx-col md:w-1/1 w-full mb-base ",
        detail: false,
        deleteId: null,
        id: null,
        table: this.tableDefaultState()
      };
    },
    methods: {
      tableDefaultState() {
        return {
          data: [],
          length: 10,
          page: 1,
          search: "",
          order: "id",
          sort: "desc",
          total: 0,
          totalPage: 0,
          totalSearch: 0,
          limits: [10, 25, 50, 100, "All"],
          start: 1,
          end: 0
        };
      },
      handleSearch(searching) {
        this.table.search = searching;
        this.table.page = 1;
        this.getData();
      },
      handleChangePage(page) {
        this.table.page = page;
        this.getData();
      },
      handleSort(key, active) {
        this.table.order = key;
        this.table.sort = active;
        this.getData();
      },
      handleChangelength(val) {
        this.table.length = val == "All" ? this.table.total : val;
        this.table.page = 1;
        this.getData();
      },
      handleClose() {
        this.getData();
        this.detail = false;
      },
      handleCreate() {
        this.id = null;
        this.detail = true;
      },
      handleDelete(id) {
        this.deleteId = id;
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirm`,
          text: "Please confirm to delete this data",
          accept: this.acceptDelete
        });
      },
      acceptDelete() {
        this.$vs.loading();
        this.$http
          .delete(this.baseUrl + "/" + this.deleteId)
          .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: "The data was successfully deleted",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
            this.getData();
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      },
      getData() {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl, {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort
            }
          })
          .then(resp => {
            if (resp.status == "success") {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              console.log(resp.data);
            }
          });
      },
      setStartEnd() {
        let valStart =
          this.table.length * this.table.page - this.table.length + 1;
        if (valStart > this.table.total) {
          valStart = 1;
        }
        if (this.table.total == 0) {
          valStart = 0;
        }
        let valEnd = this.table.length * this.table.page;
        if (valEnd > this.table.total) {
          valEnd = this.table.total;
        }
        if (this.table.totalSearch < this.table.total) {
          valEnd = this.table.totalSearch;
        }
  
        this.table.start = valStart;
        this.table.end = valEnd;
      },
      // handleDelete(id) {
      //   this.deleteId = id;
      //   this.$vs.dialog({
      //     type: "confirm",
      //     color: "danger",
      //     title: `Confirm`,
      //     text: "Please confirm to delete this data",
      //     accept: this.acceptDelete
      //   });
      // },
      // acceptDelete() {
      //   this.$vs.loading();
      //   this.$http
      //     .delete(this.baseUrl + "/" + this.deleteId)
      //     .then(resp => {
      //       this.$vs.loading.close();
      //       if (resp.code == 200) {
      //         this.$vs.notify({
      //           color: "success",
      //           title: "Success",
      //           text: "The data was successfully deleted",
      //           position: "top-right",
      //           iconPack: "feather",
      //           icon: "icon-x-circle"
      //         });
      //       } else {
      //         this.$vs.notify({
      //           color: "danger",
      //           title: "Error",
      //           text: resp.message,
      //           position: "top-right",
      //           iconPack: "feather",
      //           icon: "icon-x-circle"
      //         });
      //       }
      //       this.getData();
      //     })
      //     .catch(error => {
      //       this.$vs.loading.close();
      //       console.log(error);
      //     });
      // },
      handleEdit(id) {
        this.id = id;
        this.detail = true;
      },
      // handlePricingGroupList(id) {  
      //   this.$router.push("/master/risk-category/line/" + id);
      // },
    },
    watch: {},
    mounted() {},
    computed: {
      setPage: {
        get() {
          return 1;
        },
        set(val) {
          this.handleChangePage(val);
        }
      }
    }
  };
  </script>
  
   <style scoped>
  .core-enter-active {
    transition: all 0.3s ease;
  }
  .core-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  .core {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }
  .vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
  }
  .vertical-divider {
    border-left: 1px solid #7367f0;
    min-height: 800px;
    padding: 5px;
  }
  </style>