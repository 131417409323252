<template>
  <div class="vx-col md:w-1/1">
    <!-- <perfect-scrollbar> -->
    <div class="vx-col md:w-1/1">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Bussiness Partner</label>
          <bussiness-partner
            :id="data.parentId"
            @data="setDataBussinessPartner"
          ></bussiness-partner>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Distribution Channel</label>
          <div class="vx-row">
            <div class="vx-col w-5/6">
              <multiselect
                v-model="customerCategory.selected"
                :options="customerCategory.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="vx-col w-1/6">
              <vs-button
                color="dark"
                type="line"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="pushToCustomerCategory"
              ></vs-button>
            </div>
          </div>

          <span
            class="text-danger text-sm"
            v-show="errors.has('CustomerCategory')"
            >{{ errors.first("CustomerCategory") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Group 1</label>
          <div class="vx-row">
            <div class="vx-col w-5/6">
              <multiselect
                v-model="customerGroup1.selected"
                :options="customerGroup1.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="vx-col w-1/6">
              <vs-button
                color="dark"
                type="line"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="pushToCustomerGroup"
              ></vs-button>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('CustomerGroup1')"
            >{{ errors.first("CustomerGroup1") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Group 2</label>
          <div class="vx-row">
            <div class="vx-col w-5/6">
              <multiselect
                v-model="customerGroup2.selected"
                :options="customerGroup2.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="vx-col w-1/6">
              <vs-button
                color="dark"
                type="line"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="pushToCustomerGroup"
              ></vs-button>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Group 3</label>
          <div class="vx-row">
            <div class="vx-col w-5/6">
              <multiselect
                v-model="customerGroup3.selected"
                :options="customerGroup3.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.name }}</span>
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="vx-col w-1/6">
              <vs-button
                color="dark"
                type="line"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="pushToCustomerGroup"
              ></vs-button>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-5/6">
          <label class="vs-input--label">Pricing Group</label>
          <multiselect
            v-model="pricingGroup.selected"
            :options="pricingGroup.options"
            placeholder="Type to search"
            track-by="name"
            label="name"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >{{ props.option.name }} ({{ props.option.code }})</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >{{ props.option.name }} ({{ props.option.code }})</span
                >
              </div>
            </template>
          </multiselect>
        </div>
        <div class="vx-col w-1/6 mt-4">
          <vs-button
            color="dark"
            type="line"
            icon-pack="feather"
            icon="icon-plus-square"
            @click="pushToPricingGroup"
          ></vs-button>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Payment Type</label>
          <multiselect
            v-model="paymentType.selected"
            :options="paymentType.options"
            placeholder="Type to search"
            track-by="name"
            label="name"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Payment Term</label>
          <div class="vx-row">
            <div class="vx-col w-5/6">
              <multiselect
                v-model="paymentTerm.selected"
                :options="paymentTerm.options"
                placeholder="Type to search"
                track-by="name"
                label="name"
                :max-height="125"
              >
                <span slot="noResult">Oops! No data found</span>
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ props.option.code }} {{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="vx-col w-1/6">
              <vs-button
                color="dark"
                type="line"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="pushToPaymentTerm"
              ></vs-button>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('PaymentTerm')"
            >{{ errors.first("PaymentTerm") }}</span
          >
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Delivery Type</label>
          <multiselect
            v-model="deliveryType.selected"
            :options="deliveryType.options"
            placeholder="Type to search"
            track-by="id"
            label="name"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Priority</label>
          <multiselect
            v-model="priority.selected"
            :options="priority.options"
            placeholder="Type to search"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Block Status</label>
          <multiselect
            v-model="blockStatus.selected"
            :options="blockStatus.options"
            placeholder="Type to search"
            :max-height="125"
          >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
    </div>

    <!-- </perfect-scrollbar> -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">NEXT</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import PerfectScrollbar from "vue-perfect-scrollbar";
import PaymentMethod from "../form-component/PaymentMethod";
import BussinessPartner from "../form-component/BussinessPartner";
import Status from "../form-component/Status";
import PriorityType from "../form-component/PriorityType";
import AddressComponent from "@/components/master/Address";

export default {
  components: {
    PerfectScrollbar,
    PaymentMethod,
    BussinessPartner,
    Status,
    PriorityType,
    AddressComponent,
  },
  props: {
    customerId: {
      type: Number,
    },
    data: {
      type: Object,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/customer",
        bussinessPartner: {
          selected: null,
          options: [],
        },

        customerCategory: {
          selected: null,
          options: [],
        },
        customerGroup1: {
          selected: null,
          options: [],
        },
        customerGroup2: {
          selected: null,
          options: [],
        },
        customerGroup3: {
          selected: null,
          options: [],
        },
        paymentTerm: {
          selected: null,
          options: [],
        },
        pricingGroup: {
          selected: null,
          options: [],
        },
        deliveryType: {
          selected: null,
          options: [],
        },

        priority: {
          selected: "Platinum",
          options: ["Platinum", "Gold", "Silver", "Bronze"],
        },
        paymentType: {
          selected: null,
          options: [],
        },
        blockStatus: {
          selected: 0,
          options: [0, 1, 2, 3],
        },
      };
    },
    paramData() {
      return {
        bussinessPartner: this.bussinessPartner.selected,
        customer_group_id1: this.customerGroup1.selected.id
          ? this.customerGroup1.selected.id
          : this.customerGroup1.selected.ID,
        customer_group_id2: this.customerGroup2.selected.id
          ? this.customerGroup2.selected.id
          : this.customerGroup2.selected.ID,
        customer_group_id3: this.customerGroup3.selected.id
          ? this.customerGroup3.selected.id
          : this.customerGroup3.selected.ID,
        payment_term_id: this.paymentTerm.selected.id
          ? this.paymentTerm.selected.id
          : this.paymentTerm.selected.ID,
        pricing_group_id: this.pricingGroup.selected.id
          ? this.pricingGroup.selected.id
          : this.pricingGroup.selected.ID,
        payment_method_id: this.paymentType.selected.id
          ? this.paymentType.selected.id
          : this.paymentType.selected.ID,
        customer_category_id: this.customerCategory.selected.id
          ? this.customerCategory.selected.id
          : this.customerCategory.selected.ID,
        delivery_type_id: this.deliveryType.selected.id,
        block_status: this.blockStatus.selected,

        type: "End Customer",
        priority_type: this.priority.selected,
      };
    },
    getAllDataCustomerCategory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-category", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerCategory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataCustomerGroup1() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-group", {
          params: {
            order: "name",
            sort: "asc",
            level: 1,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup1.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataCustomerGroup2() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-group", {
          params: {
            order: "name",
            sort: "asc",
            level: 2,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup2.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    getAllDataCustomerGroup3() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-group", {
          params: {
            order: "name",
            sort: "asc",
            level: 3,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup3.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataPricingGroup() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/pricing-group", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.pricingGroup.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataPaymentTerm() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/payment-term", {
          params: {
            order: "name",
            sort: "asc",
            //payment_method_id: this.data.paymentMethodId,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.paymentTerm.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataPaymentType() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/payment-method", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.paymentType.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getAllDataDeliveryType() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/delivery-type", {
          params: {
            order: "code",
            sort: "asc",
            type: "Sales",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.deliveryType.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setCustomerCategorySelected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-category/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerCategory.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCustomerGroup1Selected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup1.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCustomerGroup2Selected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup2.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCustomerGroup3Selected(id) {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup3.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setPricingGroupSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/pricing-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.pricingGroup.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setPaymentTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/payment-method/" + id).then((resp) => {
        if (resp.status == "success") {
          this.paymentType.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setPaymentTermSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/payment-term/" + id).then((resp) => {
        if (resp.status == "success") {
          console.log("paymentTerm.selected", resp.data);
          this.paymentTerm.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setDeliveryTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/delivery-type/" + id).then((resp) => {
        if (resp.status == "success") {
          console.log("deliveryType.selected", resp.data);
          this.deliveryType.selected = resp.data[0];
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    pushToCustomerCategory() {
      this.$emit("tab", "Distribution Channel");
    },
    pushToPricingGroup() {
      this.$emit("tab", "Pricing Group");
    },
    pushToCustomerGroup() {
      this.$emit("tab", "Customer Group");
    },
    pushToPaymentTerm() {
      // this.$emit("tab", "Payment Term");
      this.$router.push("/master/payment-term");
    },
    handleClose() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!this.data.customerCategoryId) {
          this.errors.add({
            field: "CustomerCategory",
            msg: "Distribution Channel field is required",
          });
          result = false;
        }
        if (!this.data.customerGroupId1) {
          this.errors.add({
            field: "CustomerGroup1",
            msg: "Customer Group 1 field is required",
          });
          result = false;
        }
        if (!this.data.paymentTermId) {
          this.errors.add({
            field: "PaymentTerm",
            msg: "Payment Term field is required",
          });
          result = false;
        }

        if (result) {
          console.log("attribute-tabdata>>>>>>>>", this.data);
          this.$emit("postDataAttribute", this.paramData());
          this.$emit("tab", "CP");
        }
      });
    },

    setDataBussinessPartner(val) {
      this.data.bussinessPartner = val;
    },
  },
  mounted() {
    this.getAllDataCustomerCategory();
    this.getAllDataPricingGroup();
    this.getAllDataCustomerGroup1();
    this.getAllDataCustomerGroup2();
    this.getAllDataCustomerGroup3();
    this.getAllDataPaymentTerm();
    this.getAllDataPaymentType();
    this.getAllDataDeliveryType();
    // this.getCode();
    //this.getAllDataTerritory();
    if (this.customerId) {
      console.log("disini DATA NYA", this.customerId);
    }
    if (this.data) {
      if (this.data.customerCategoryId) {
        this.setCustomerCategorySelected(this.data.customerCategoryId);
      }
      if (this.data.customerGroupId1) {
        this.setCustomerGroup1Selected(this.data.customerGroupId1);
      }
      if (this.data.customerGroupId2) {
        this.setCustomerGroup2Selected(this.data.customerGroupId2);
      }
      if (this.data.customerGroupId3) {
        this.setCustomerGroup3Selected(this.data.customerGroupId3);
      }
      if (this.data.pricingGroupId) {
        this.setPricingGroupSelected(this.data.pricingGroupId);
      }
      if (this.data.paymentTermId) {
        this.setPaymentTermSelected(this.data.paymentTermId);
      }
      if (this.data.deliveryTypeId) {
        this.setDeliveryTypeSelected(this.data.deliveryTypeId);
      }
      if (this.data.paymentTypeId) {
        this.setPaymentTypeSelected(this.data.paymentTypeId);
      }

      console.log("disini yaaWWWWW", this.data);
    }
  },
  computed: {},
  watch: {
    customerId(v) {
      this.getAllDataCustomerCategory();
      this.getAllDataCustomerGroup1();
      this.getAllDataCustomerGroup2();
      this.getAllDataCustomerGroup3();
      this.getAllDataPaymentTerm();
      this.getAllDataDeliveryType();
      //this.getAllDataTerritory();
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },

    "customerCategory.selected": function (val) {
      if (val) {
        this.errors.clear();
        this.data.customerCategoryId = val.id ? val.id : val.ID;
      } else {
        this.data.customerCategoryId = null;
      }
    },
    "pricingGroup.selected": function (val) {
      if (val) {
        this.errors.clear();
        this.data.pricingGroupId = val.id ? val.id : val.ID;
      } else {
        this.data.pricingGroupId = null;
      }
    },
    "customerGroup1.selected": function (val) {
      if (val) {
        //console.log(val)
        this.errors.clear();
        this.data.customerGroupId1 = val.id ? val.id : val.ID;
        if (
          this.paymentType.selected == "Credit" &&
          this.data.customerGroupId1 != null &&
          this.data.customerGroupId2 != null &&
          this.data.customerGroupId3 != null
        ) {
          this.$vs.loading();
          this.$http
            .get("/api/v1/master/customer-group-limit/payment-term", {
              params: {
                customer_group_id1: this.data.customerGroupId1,
                customer_group_id2: this.data.customerGroupId2,
                customer_group_id3: this.data.customerGroupId3,
              },
            })
            .then((resp) => {
              if (resp.status == "success") {
                //this.paymentTerm.selected = resp.data[0].payment_term;
                this.data.customerLimit.amount =
                  resp.data[0].records.credit_limit
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                this.$vs.loading.close();
              } else {
                this.$vs.loading.close();
              }
            });
        }
      } else {
        this.data.customerGroupId1 = null;
      }
    },
    "customerGroup2.selected": function (val) {
      if (val) {
        //console.log(val)
        this.errors.clear();
        this.data.customerGroupId2 = val.id ? val.id : val.ID;
        if (
          this.paymentType.selected == "Credit" &&
          this.data.customerGroupId1 != null &&
          this.data.customerGroupId2 != null &&
          this.data.customerGroupId3 != null
        ) {
          this.$vs.loading();
          this.$http
            .get("/api/v1/master/customer-group-limit/payment-term", {
              params: {
                customer_group_id1: this.data.customerGroupId1,
                customer_group_id2: this.data.customerGroupId2,
                customer_group_id3: this.data.customerGroupId3,
              },
            })
            .then((resp) => {
              if (resp.status == "success") {
                //this.paymentTerm.selected = resp.data[0].payment_term;
                this.data.customerLimit.amount =
                  resp.data[0].records.credit_limit
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                this.$vs.loading.close();
              } else {
                this.$vs.loading.close();
              }
            });
        }
      } else {
        this.data.customerGroupId2 = null;
      }
    },
    "customerGroup3.selected": function (val) {
      if (val) {
        //console.log(val)
        this.errors.clear();
        this.data.customerGroupId3 = val.id ? val.id : val.ID;
        if (
          this.paymentType.selected == "Credit" &&
          this.data.customerGroupId1 != null &&
          this.data.customerGroupId2 != null &&
          this.data.customerGroupId3 != null
        ) {
          this.$vs.loading();
          this.$http
            .get("/api/v1/master/customer-group-limit/payment-term", {
              params: {
                customer_group_id1: this.data.customerGroupId1,
                customer_group_id2: this.data.customerGroupId2,
                customer_group_id3: this.data.customerGroupId3,
              },
            })
            .then((resp) => {
              if (resp.status == "success") {
                //this.paymentTerm.selected = resp.data[0].payment_term;
                this.data.customerLimit.amount =
                  resp.data[0].records.credit_limit
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                this.$vs.loading.close();
              } else {
                this.$vs.loading.close();
              }
            });
        }
      } else {
        this.data.customerGroupId3 = null;
      }
    },
    "paymentType.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        console.log("paymentType.selected", val);
        //if (val == "Credit" && this.customerId == null && this.data.customerGroupId1 != null && this.data.customerGroupId2 != null && this.data.customerGroupId3 != null) {
        if (val == "Credit") {
          this.$vs.loading();
          this.$http
            .get("/api/v1/master/payment-term/non-cod", {
              params: {
                order: "name",
                sort: "asc",
              },
            })
            .then((resp) => {
              if (resp.status == "success") {
                this.paymentTerm.options = resp.data.records;
                //this.paymentTerm.selected = resp.data.records[0];
                this.$vs.loading.close();
              } else {
                this.$vs.loading.close();
              }
            });
        } else if (val == "Cash") {
          this.$vs.loading();
          this.$http
            .get("/api/v1/master/payment-term/cod", {
              params: {
                payment_term_id: 7, //id untuk cod
                order: "name",
                sort: "asc",
              },
            })
            .then((resp) => {
              if (resp.status == "success") {
                this.paymentTerm.options = resp.data.records;
                //this.paymentTerm.selected = resp.data.records[0];
                this.$vs.loading.close();
              } else {
                this.$vs.loading.close();
              }
            });
        }
        this.paymentType.selected = val;
      } else {
        this.paymentType.selected = "Cash";
      }
    },
    "paymentTerm.selected": function (val) {
      // console.log(val, "paymentTerm.selected")
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.paymentTermId = val.id ? val.id : val.ID;
        this.data.paymentMethodId = val.payment_method_id;
      } else {
        this.data.paymentTermId = null;
      }
    },

    "deliveryType.selected": function (val) {
      console.log(val, "deliveryType.selected");
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.deliveryTypeId = val.id;
      } else {
        this.data.deliveryTypeId = null;
      }
    },
    "priority.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.priorityType = val;
      } else {
        this.data.priorityType = null;
      }
    },
  },
};
</script>
<style>
.ps {
  height: 800px;
}
</style>
