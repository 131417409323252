<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id!=null">Edit</b>
      <b v-else>Create</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Customer Category</label>
        <div class="vx-row">
          <div class="vx-col w-full">
            <multiselect
              v-model="customerCategory.selected"
              :options="customerCategory.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <span
          class="text-danger text-sm"
          v-show="errors.has('CustomerCategory')"
          >{{ errors.first("CustomerCategory") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Customer Group 1</label>
        <div class="vx-row">
          <div class="vx-col w-full">
            <multiselect
              v-model="customerGroup1.selected"
              :options="customerGroup1.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <span
          class="text-danger text-sm"
          v-show="errors.has('CustomerGroup1')"
          >{{ errors.first("CustomerGroup1") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Customer Group 2</label>
        <div class="vx-row">
          <div class="vx-col w-full">
            <multiselect
              v-model="customerGroup2.selected"
              :options="customerGroup2.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <span
          class="text-danger text-sm"
          v-show="errors.has('CustomerGroup2')"
          >{{ errors.first("CustomerGroup2") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Customer Group 3</label>
        <div class="vx-row">
          <div class="vx-col w-full">
            <multiselect
              v-model="customerGroup3.selected"
              :options="customerGroup3.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <span
          class="text-danger text-sm"
          v-show="errors.has('CustomerGroup3')"
          >{{ errors.first("CustomerGroup3") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Term</label>
        <div class="vx-row">
          <div class="vx-col w-full">
            <multiselect
              v-model="paymentTerm.selected"
              :options="paymentTerm.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.code }} {{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.code }} {{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <span
          class="text-danger text-sm"
          v-show="errors.has('PaymentTerm')"
          >{{ errors.first("PaymentTerm") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Credit Limit"
            v-model="data.creditLimit"
            @keyup="data.creditLimit = formatPrice(data.creditLimit.toString())"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('CreditLimit')"
            >{{ errors.first("CreditLimit") }}</span
          >
        </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number
    },
    url: {
      type: String
    }
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          creditLimit: 0,
          customerCategoryId: null,
          customerGroupId1: null,
          customerGroupId2: null,
          customerGroupId3: null,
          paymentTermId: null,
        },
        customerCategory: {
          selected: null,
          options: [],
        },       
        customerGroup1: {
          selected: null,
          options: [],
        },      
        customerGroup2: {
          selected: null,
          options: [],
        },      
        customerGroup3: {
          selected: null,
          options: [],
        },
        paymentTerm: {
          selected: null,
          options: [],
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (!this.data.customerGroupId1) {
          this.errors.add({
            field: "customerGroup1",
            msg: "The Customer Group 1 field is required",
          });
          result = false;
        }
        if (!this.data.customerGroupId2) {
          this.errors.add({
            field: "customerGroup2",
            msg: "The Customer Group 2 field is required",
          });
          result = false;
        }
        if (!this.data.customerGroupId3) {
          this.errors.add({
            field: "customerGroup3",
            msg: "The Customer Group 3 field is required",
          });
          result = false;
        }
        if (!this.data.paymentTermId) {
          this.errors.add({
            field: "PaymentTerm",
            msg: "The Payment Term field is required",
          });
          result = false;
        }
        if (result) {
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      return {
        credit_limit: parseInt(this.data.creditLimit.replace(/[^,\d]/g, "").toString()),
        customer_category_id: this.data.customerCategoryId,
        customer_group_id1: this.data.customerGroupId1,
        customer_group_id2: this.data.customerGroupId2,
        customer_group_id3: this.data.customerGroupId3,
        payment_term_id: this.data.paymentTermId,
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.id = resp.data.ID;
            this.data.creditLimit = resp.data.credit_limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            if (resp.data.customer_category_id) {
              this.setCustomerCategorySelected(resp.data.customer_category_id);
            }
            if (resp.data.customer_group_id1) {
              this.setCustomerGroup1Selected(resp.data.customer_group_id1);
            }
            if (resp.data.customer_group_id2) {
              this.setCustomerGroup2Selected(resp.data.customer_group_id2);
            }
            if (resp.data.customer_group_id3) {
              this.setCustomerGroup3Selected(resp.data.customer_group_id3);
            }
            if (resp.data.payment_term_id) {
              this.setPaymentTermSelected(resp.data.payment_term_id);
            }

          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getDataCustomerCategory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-category", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerCategory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataCustomerGroup1() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-group", {
          params: {
            order: "name",
            sort: "asc",
            level: "1",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup1.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataCustomerGroup2() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-group", {
          params: {
            order: "name",
            sort: "asc",
            level: "2",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup2.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataCustomerGroup3() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer-group", {
          params: {
            order: "name",
            sort: "asc",
            level: "3",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.customerGroup3.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataPaymentTerm() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/payment-term", {
          params: {
            order: "name",
            sort: "asc",
            //payment_method_id: this.data.paymentMethodId,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.paymentTerm.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setCustomerCategorySelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/customer-category/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerCategory.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCustomerGroup1Selected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/customer-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup1.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },    
    setCustomerGroup2Selected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/customer-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup2.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },  
    setCustomerGroup3Selected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/customer-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.customerGroup3.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },  
    setPaymentTermSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/payment-term/" + id).then((resp) => {
        if (resp.status == "success") {
          this.paymentTerm.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    formatPrice(angka, prefix = "") {
      console.log(angka);
      var number_string = angka.replace(/[^,\d]/g, "").toString();
      var split = number_string.split(",");
      split[0] = parseInt(split[0]).toString();
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      var separator = "";
      if (ribuan) {
        if (sisa) {
          separator = ".";
        } else {
          separator = "";
        }
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
    },
  },
  mounted() {    
    this.getDataCustomerCategory();
    this.getDataCustomerGroup1();
    this.getDataCustomerGroup2();
    this.getDataCustomerGroup3();
    this.getDataPaymentTerm();
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
    "paymentTerm.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.paymentTermId = val.id ? val.id : val.ID;
      } else {
        this.data.paymentTermId = null;
      }
    },
    "customerCategory.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.customerCategoryId = val.id ? val.id : val.ID;
      } else {
        this.data.customerCategoryId = 0;
      }
    },
    "customerGroup1.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.customerGroupId1 = val.id ? val.id : val.ID;
      } else {
        this.data.customerGroupId1 = null;
      }
    },
    "customerGroup2.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.customerGroupId2 = val.id ? val.id : val.ID;
      } else {
        this.data.customerGroupId2 = null;
      }
    },
    "customerGroup3.selected": function (val) {
      if (val && val !== undefined) {
        this.errors.clear();
        this.data.customerGroupId3 = val.id ? val.id : val.ID;
      } else {
        this.data.customerGroupId3 = null;
      }
    },
  }
};
</script>
<style >
</style>
