<template>
  <div class="vx-col md:w-1/1">
    <!-- <perfect-scrollbar> -->
    <div class="vx-col md:w-1/1">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Contact Name"
            v-model="data.contactName"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="NIK" v-model="data.nik" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Phone"
            v-model="data.phone"
            v-validate="'required|numeric'"
            name="Phone"
          />
          <span class="text-danger text-sm" v-show="errors.has('Phone')">{{
            errors.first("Phone")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Mobile"
            v-model="data.mobile"
            v-validate="'numeric'"
            name="Mobile"
          />
          <span class="text-danger text-sm" v-show="errors.has('Mobile')">{{
            errors.first("Mobile")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Email"
            v-model="data.email"
            v-validate="'email'"
            name="Email"
          />
          <span class="text-danger text-sm" v-show="errors.has('Email')">{{
            errors.first("Email")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            v-if="customerId != null"
            class="w-full"
            label="Password"
            v-model="data.password"
            name="password"
            type="password"
          />
          <vs-input
            v-else
            class="w-full"
            label="Password"
            v-validate="'required'"
            v-model="data.password"
            name="password"
            type="password"
          />
          <span class="text-danger text-sm" v-show="errors.has('Password')">{{
            errors.first("Password")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Fax"
            v-model="data.fax"
            v-validate="'numeric'"
            name="Fax"
          />
          <span class="text-danger text-sm" v-show="errors.has('Fax')">{{
            errors.first("Fax")
          }}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <span>Photo</span>
        </div>
        <div class="vx-col w-full">
          <input
            name="file"
            class="w-full inputx"
            type="file"
            ref="file"
            accept=".jpg, .jpeg, .png"
            @change="onFileChange"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <img style="max-height: 170px; max-width: auto" :src="data.photo" />
        </div>
      </div>
    </div>

    <!-- </perfect-scrollbar> -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">NEXT</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    customerId: {
      type: Number,
    },
    data: {
      type: Object,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/customer",
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!this.data.email) {
          this.errors.add({
            field: "Email",
            msg: "The Email field is required",
          });
          result = false;
        }
        if (!this.data.password && this.customerId == null) {
          this.errors.add({
            field: "Password",
            msg: "The Password field is required",
          });
          result = false;
        }
        if (this.data.password.length < 8 && this.customerId == null) {
          this.errors.add({
            field: "Password",
            msg: "The Password at least 8 character",
          });
          result = false;
        }

        if (result) {
          console.log("cp-tabdata>>>>>>>>", this.data);
          this.$emit("postDataCp", this.paramData());
          this.$emit("tab", "CL & Tax");
        }
      });
    },
    paramData() {
      return {
        contact_name: this.data.contactName,
        mobile: this.data.mobile,
        phone: this.data.phone,
        email: this.data.email,
        password: this.data.password,
        fax: this.data.fax === "" ? "" : this.data.fax,
        nik: this.data.nik,
        photo: this.$refs.file.files[0] ? this.$refs.file.files[0] : null,
      };
    },
    handleClose() {
      this.$emit("close");
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
    },
    createImage(file) {
      console.log("file cp ", file)
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.data.photo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.data.photo = file[0]
    },
  },
  mounted() {
    console.log("ini datanyaaa cplllllllllllll", this.data);
  },
  computed: {},
  watch: {
    customerId(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
    "data.password": function (val) {
      if (val.length >= 8) {
        this.errors.clear();
      }
    },
  },
};
</script>
<style>
.ps {
  height: 800px;
}
</style>
